<template>
	
  <Page>
		<Header>
			<div ref="top" class="anchor"></div>
			<Stack justify="spaceBetween">
				<Link url="/" display="inline">
					<Stack height="60px" width="200px" justify="center" border="r">
						<Heading size="5" color="tertiary" margin="none" uppercase>Anna Robbins</Heading>
					</Stack>
				</Link>
				<Stack height="60px" width="460px" justify="center" border="l" space="2" visibility="hide show@md">
					<Link url="/" text="PORTFOLIO" display="inline" />
					<Link url="/about" text="ABOUT" display="inline" />
					<Link url="/contact" text="CONTACT" display="inline" />
					<Button url="/design-system" label="DESIGN SYSTEM" type="outline" color="tertiary" newTab />
				</Stack>
				<Stack visibility="show hide@md" v-click-outside="setClose">
					<Stack height="60px" width="60px" justify="center" border="n l@sm">
						<Link @click.native="setOpen()" display="inline" url="">
							<Icon icon="menu" color="tertiary" size="large" v-if="!isOpen" />
							<Icon icon="menu_open" color="tertiary" size="large" v-if="isOpen" />
						</Link>
					</Stack>
					<Dropdown :open="isOpen">
						<Stack direction="column" space="1">
							<Link text="PORTFOLIO" color="tertiary" display="inline" url="/" />
							<Link text="ABOUT" color="tertiary" display="inline" url="/about" />
							<Link text="CONTACT" color="tertiary" display="inline" url="/contact" />
							<Button url="/design-system" label="DESIGN SYSTEM" type="outline" color="tertiary" newTab />
						</Stack>
					</Dropdown>
				</Stack>
			</Stack>
		</Header>
		<Body>
			<slot></slot>
		</Body>
		<Footer>
			<Stack justify="spaceBetween">
				<Link url="/" display="inline">
					<Stack height="60px" width="200px" justify="center" border="r">
						<Heading size="5" color="tertiary" margin="none" uppercase>Anna Robbins</Heading>
					</Stack>
				</Link>
				<Link url="" display="inline" @click.native="scrollMeTo('top')">
					<Stack height="60px" width="60px" justify="center" border="n l@sm">
						<Icon icon="arrow_upward" size="large" color="tertiary" />
					</Stack>
				</Link>
			</Stack>
		</Footer>
	</Page>
</template>

<script>
export default {
  data() {
    return {
			isOpen: false,
		}
  },
	methods: {
		scrollMeTo(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo({
				top: top,
				behavior: 'smooth'
			});
		},
    setOpen() {
			this.isOpen = !this.isOpen;
    },
    setClose() {
      this.isOpen = false
    },
	}
}
</script>

<style lang="scss" scoped>

</style>