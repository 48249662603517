<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Stack border="b">
					<Section>
						<Heading size="1" uppercase>Dallas</Heading>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack width="100%" border="b">
					<Stack width="100%" justify="spaceBetween">
						<Link url="/portfolio/windermere" display="inline">
							<Stack height="60px" width="70px" justify="center" border="r">
								<Icon icon="arrow_left" size="large" color="tertiary" />
							</Stack>
						</Link>
						<Link url="/portfolio/edison" display="inline">
							<Stack height="60px" width="70px" justify="center" border="l">
								<Icon icon="arrow_right" size="large" color="tertiary" />
							</Stack>
						</Link>
					</Stack>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%">
					<Section>
						<Laptop image="theme-ar/dallas3.jpg" />
					</Section>
					<Stack width="100%" border="b"></Stack>
					<Section size="large">
						<Stack direction="column" space="1">
							<Images width="100%" image="theme-ar/dallas2.jpg" border />
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%" border="t l@md">
					<Stack width="100%">
						<Section>
							<Heading size="2">A creative direction inspired by artists.</Heading> 
							<Paragraph>I wanted to put art at the center of the strategy by giving it an important place in the simple highlighting of photography of the site. From this strategy emerges an interactive, visual, and content-rich site, perfectly promoting the modernity of the company.</Paragraph>
							<Paragraph>To highlight the creations, I started by collecting photographs of works to use on the website. It is with this material that I have tried to create an original representation in a refined site. I created a simple one-page site, but rich in visual elements.</Paragraph>
							<Button url="/dallas" label="GO TO WEBSITE" color="tertiary" newTab />
						</Section>
					</Stack>
					<Stack width="100%" border="b"></Stack>
					<Section>
						<Browser image="theme-ar/dallas1.jpg" />
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack border="b"></Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 4@md">
				<Stack width="100%">
					<Section>
						<Heading size="2" margin="none" uppercase>Design Elements</Heading>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack direction="column" align="left" width="100%" height="100%" border="t l@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Colors</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Color color="#4AD7D1" title="Brand Primary"></Color>
							<Color color="#FE4A49" title="Brand Secondary"></Color>
							<Color color="#001730" title="Brand Tertiary"></Color>
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack direction="column" align="left" justify="spaceBetween" width="100%" height="100%" border="t l@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Typography</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-dl"><Heading size="2" margin="none">Heading</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-dl"><Heading size="3" margin="none">Accent</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-dl"><Heading size="4" margin="none">Sub Header</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-dl"><Paragraph margin="none">Body Copy</Paragraph></Stage>
						</Stack>
					</Section>
					<Stack width="100%"></Stack>
				</Stack>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/annarobbins/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
